import React, { useState, useEffect } from "react";
import {
    Button,
    Grid,
    TextField,
    Typography,
    Card,
    CardContent,
} from "@mui/material";
import { networkRequest } from "../../utils/network_request";
import { useLoading } from "../../utils/LoadingContext";
import { apiAuth } from "../../utils/urls";

export default function Settings() {
    const [coinSettings, setCoinSettings] = useState([]);
    const [videoLink, setVideoLink] = useState("");
    const { setLoading } = useLoading();

    // Fetch coin settings and video link
    useEffect(() => {
        const fetchSettings = () => {
            networkRequest(
                apiAuth.getSettingCoins,
                setLoading,
                (response) => {
                    if (response.success) {
                        setCoinSettings(response.data);
                        setVideoLink(response.video || "");
                    }
                },
                "get"
            );
        };
        fetchSettings();
    }, [setLoading]);

    // Update all coin settings
    const updateAllCoins = () => {
        const formData = {};
        coinSettings.forEach((setting) => {
            formData[setting.name] = setting.coin;
        });

        networkRequest(
            apiAuth.updateSettingCoins,
            setLoading,
            (response) => {
                if (response.success) {
                    alert("All coin settings updated successfully!");
                }
            },
            "post",
            formData
        );
    };

    // Update video link
    const updateVideoLink = () => {
        const formData = {
            "link": videoLink
        }

        networkRequest(
            apiAuth.updateIntroVideo,
            setLoading,
            (response) => {
                if (response.success) {
                    alert("Video link updated successfully!");
                }
            },
            "post",
            formData
        );
    };

    // Handle change in coin text fields
    const handleCoinChange = (id, value) => {
        setCoinSettings((prevSettings) =>
            prevSettings.map((setting) =>
                setting.id === id ? { ...setting, coin: value } : setting
            )
        );
    };

    return (
        <>
            <Grid container spacing={2} sx={{ p: 2 }}>
                {/* Coin Settings Form */}
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Coin Settings
                    </Typography>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                {coinSettings.map((setting) => (
                                    <Grid item xs={12} md={6} lg={4} key={setting.id}>
                                        <TextField
                                            label={`${setting.name.toUpperCase()} Coins`}
                                            type="number"
                                            value={setting.coin}
                                            onChange={(e) => handleCoinChange(setting.id, e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={updateAllCoins}
                                sx={{ mt: 2 }}
                            >
                                Update All Coins
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Video Settings Form */}
                <Grid item xs={12} sx={{ mt: 4 }}>
                    <Typography variant="h5" gutterBottom>
                        Video Settings
                    </Typography>
                    <Card>
                        <CardContent>
                            <TextField
                                label="Intro Video Link"
                                type="url"
                                value={videoLink}
                                onChange={(e) => setVideoLink(e.target.value)}
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={updateVideoLink}
                            >
                                Update Video Link
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
