import React, { useCallback, useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Modal,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Stack,
  Pagination,
  ButtonGroup,
  Switch,
  Tooltip,
  debounce,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { networkRequest } from "../../utils/network_request";
import { apiAuth } from "../../utils/urls";
import { useLoading } from "../../utils/LoadingContext";
import { domain } from "../../utils/domain";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "auto",
  maxHeight: "80vh",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  p: 3,
  backgroundColor: "white",
  borderRadius: "8px",
};

function Posts() {
  const { setLoading } = useLoading();
  const [postDetails, setPostDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [taluka, setTaluka] = useState("");
  const [village, setVillage] = useState("");
  const [like, setLike] = useState("");
  const [status, setStatus] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [villages, setVillages] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [search, setSearch] = useState("");
  const [location_type, setLocationType] = useState("");
  const [location_id, setLocationId] = useState("");
  const [isVerified, setIsVerified] = useState("all");


  const handleBlockToggle = (post) => {
    console.log(post.status);
    const blockStatus = {
      post_id: post.id,
      status: post.status === "active" ? "0" : "1",
    };
    networkRequest(
      apiAuth.setBlockStatus,
      setLoading,
      (res) => {
       //update the post status in the posts array
        const updatedPosts = posts.map((p) => {
          if (p.id === post.id) {
            return {
              ...p,
              status: blockStatus.status !== "1" ? "blocked" : "active",
            };
          }
          return p;
        });
        setPosts(updatedPosts)
      },
      "POST",
      blockStatus
    );
  };
  const handlePageChange = (event, value) => {
    setPage(value);
    fetchPosts("", "", "", like, status);
  };

  
  const handleVerifyToggle = (post) => {
    const blockStatus = {
      post_id: post.id,
      status: post.is_verified === "0" ? "1" : "0",
    };
    networkRequest(
      apiAuth.setVerificationStatus,
      setLoading,
      (res) => {
        //update the post status in the posts array
        const updatedPosts = posts.map((p) => {
          if (p.id === post.id) {
            return {
              ...p,
              is_verified: blockStatus.status === "1" ? "1" : "0",
            };
          }
          return p;
        });
        setPosts(updatedPosts);
      },
      "POST",
      blockStatus
    );
  };

  const handleOpen = (details) => {
    setPostDetails(details);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const truncateText = (text, wordLimit) => {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const fetchPosts = useCallback(async () => {
    var data = {
      page: currentPage,
      like: true,
      search: search,
      location_type: location_type,
      location_id: location_id,
      filter: status,
      sort: like,
      is_verified: isVerified,
    };
    
    try {
      let url = `${apiAuth.getPosts}?page=${currentPage}&like=true`;
      setPosts([]);
      await networkRequest(
        url,
        (t) => setLoading(t),
        (response) => {
          setPosts(response.data);
          setTotalPages(response.pagination.total_pages);
        },
        "GET",
        data
      );
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  }, [currentPage, search, location_type, location_id, status, like, isVerified, setLoading]);

  useEffect(() => {
    fetchPosts();
  }, [currentPage, location_type, location_id, like, states,status, fetchPosts, isVerified]);

  useEffect(() => {
    fetchStates();
  }, []);
  const handleSearch = debounce((event) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
  }, 500);

  const fetchStates = () => {
    networkRequest(
      apiAuth.getStates,
      () => { },
      (res) => {
        setStates(res.data);
      }
    );
  };
  const fetchDistrict = (id) => {
    networkRequest(
      `${apiAuth.getDistricts}/${id}`,
      () => { },
      (res) => {
        setDistricts(res.data);
      }
    );
  };
  const fetchTaluka = (id) => {
    networkRequest(
      `${apiAuth.getTalukas}/${id}`,
      () => { },
      (res) => {
        setTalukas(res.data);
      }
    );
  };

  const fetchVillege = (id) => {
    networkRequest(
      `${apiAuth.getVillages}/${id}`,
      () => { },
      (res) => {
        setVillages(res.data);
      }
    );
  };

  const handleChange = (event) => {
    const selectedState = event.target.value;
    setState(selectedState);
    fetchDistrict(selectedState.id);
    setLocationType("state");
    setLocationId(selectedState.id);
    setPage(1);

  };
  const handleChangeDis = (event) => {
    const selectedDistrict = event.target.value;
    setDistrict(selectedDistrict);
    fetchTaluka(selectedDistrict.id);
    setLocationType("district");
    setLocationId(selectedDistrict.id);
    setPage(1);

  };
  const handleChangeTaluka = (event) => {
    const selectedTaluka = event.target.value;
    setTaluka(selectedTaluka);
    fetchVillege(selectedTaluka.id);
    setLocationType("taluka");
    setLocationId(selectedTaluka.id);
    setPage(1);


  };
  const handleChangeVillage = (event) => {
    const selectedVillage = event.target.value;
    setVillage(selectedVillage);
    setLocationType("village");
    setLocationId(selectedVillage.id);
    setPage(1);


  };
  const handleChangeLike = (event) => {
    const like = event.target.value;
    setLike(like);
    setPage(1);

  };

  const handleChangeStatus = (event) => {
    const selectedStatus = event.target.value;
    setStatus(selectedStatus);
    setPage(1);
  };

  const handleChangeVerified = (event) => {
    const selectedStatus = event.target.value;
    setIsVerified(selectedStatus);
    setPage(1);
  };

  

  return (
    <>
      <Grid>
        <Grid item>
          <h1>Posts</h1>
        </Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            gap: "15px",
            pt: 2,
            pr: 2,
            m: 2,
            maxWidth: "100vw",
          }}
        >
          <Grid item>
            <Box sx={{ minWidth: 120, mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">State</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={state}
                  label="Age"
                  onChange={handleChange}
                >
                  {states.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ minWidth: 120, mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">District</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={district}
                  label="Age"
                  onChange={handleChangeDis}
                >
                  {districts.map((district) => (
                    <MenuItem key={district.id} value={district}>
                      {district.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ minWidth: 120, mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="taluka-select-label">Taluka</InputLabel>
                <Select
                  labelId="taluka-select-label"
                  id="taluka-select"
                  value={taluka}
                  label="Taluka"
                  onChange={handleChangeTaluka}
                >
                  {talukas.map((taluka) => (
                    <MenuItem key={taluka.id} value={taluka}>
                      {taluka.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ minWidth: 120, mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="village-select-label">Village</InputLabel>
                <Select
                  labelId="village-select-label"
                  id="village-select"
                  value={village}
                  label="Village"
                  onChange={handleChangeVillage}
                >
                  {villages.map((village) => (
                    <MenuItem key={village.id} value={village}>
                      {village.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ minWidth: 120, mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Like</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={like}
                  label="Like"
                  onChange={handleChangeLike}
                >
                  <MenuItem value={"likes"}>Most Liked</MenuItem>
                  <MenuItem value={"dislikes"}>Most Disliked</MenuItem>
                  <MenuItem value={"views"}>Most Viewed</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={{ minWidth: 120, mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Status"
                  onChange={handleChangeStatus}
                >
                  <MenuItem value={"blocked"}>Blocked</MenuItem>
                  <MenuItem value={"unblock"}>Unblocked</MenuItem>
                  <MenuItem value={"all"}>All</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={{ minWidth: 120, mt: 1 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Verified/Unverified</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={isVerified}
                  label="Status"
                  onChange={handleChangeVerified}
                >
                  <MenuItem value={"false"}>Unverified</MenuItem>
                  <MenuItem value={"true"}>Verified</MenuItem>
                  <MenuItem value={"all"}>All</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </div>
        {/* Additional Filters */}
        <Grid item>
          <TextField
            id="outlined-search"
            label="Search"
            type="search"
            onChange={handleSearch}
            sx={{
              mt: 2,
              mb: 2,
              width: "220px",
              "& .MuiInputBase-root": { height: "40px" },
              "& .MuiInputLabel-root": { lineHeight: "13px" },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "100vw",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f0f0f0",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#4caf50",
            borderRadius: "10px",
            border: "2px solid #f0f0f0",
          },
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ py: 2 }}>
                Sr. No
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                TITLE
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                DESCRIPTION
              </TableCell>
              <TableCell align="center">LIKES</TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                DISLIKES
              </TableCell>
              <TableCell align="center">VIEWS</TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {posts.length > 0 ? (
              posts.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => {
                    const imageArray = [];
                    if (row.picture1) imageArray.push(row.picture1);
                    if (row.picture2) imageArray.push(row.picture2);
                    if (row.picture3) imageArray.push(row.picture3);
                    handleOpen({
                      title: row.title,
                      description: row.description,
                      like: row.like,
                      dislike: row.dislike,
                      views: row.views,
                      images: imageArray,
                      username: row.username,
                      contact: row.contact,
                      created_at: row.created_at,
                    });
                  }}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: "60px",
                    cursor: "pointer",
                    color: row.is_verified === "0" ? "orange" : "inherit",
                  }}
                >
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{
                      py: 1,
                      color: row.status !== "active" ? "red" : "inherit",
                    }}
                  >
                    {row.id}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      py: 2,
                      color: row.status !== "active" ? "red" : "inherit",
                    }}
                  >
                    {truncateText(row.title, 3) || "N/A"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      py: 2,
                      color: row.status !== "active" ? "red" : "inherit",
                    }}
                  >
                    {truncateText(row.description, 4) || "N/A"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      py: 2,
                      color: row.status !== "active" ? "red" : "inherit",
                    }}
                  >
                    {row.like}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      py: 2,
                      color: row.status !== "active" ? "red" : "inherit",
                    }}
                  >
                    {row.dislike}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      py: 2,
                      color: row.status !== "active" ? "red" : "inherit",
                    }}
                  >
                    {row.views}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      py: 2,
                      color: row.status !== "active" ? "red" : "inherit",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ButtonGroup
                      variant="contained"
                      aria-label="Basic button group"
                      sx={{ float: "flex", m: 2 }}
                    >
                      <Tooltip title="Block / Unblock">
                        <Switch
                          checked={row.status !== "active"}
                          onChange={() => handleBlockToggle(row)}
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "red",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "red",
                            },
                          }}
                        />
                      </Tooltip>
                    </ButtonGroup>
                    <ButtonGroup
                      variant="contained"
                      aria-label="Basic button group"
                      sx={{ float: "flex", m: 2 }}
                    >
                      <Tooltip title="Verified / Unverified">
                        <Switch
                          checked={row.is_verified !== "1"}
                          onChange={() => handleVerifyToggle(row)}
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "orange",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: "orange",
                            },
                          }}
                        />
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No Posts found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack spacing={2}>
        <Pagination
          count={totalPages}
          page={currentPage} // Controlled component
          onChange={handlePageChange}
          color="primary"
        />
      </Stack>

      {/* Modal for displaying images and post details */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <h2>{postDetails.title}</h2>
          <Grid container spacing={2}>
            {postDetails.images?.map((img, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <img
                  src={`${domain}/${img}`}
                  alt={`Post ${index + 1}`}
                  style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                />
              </Grid>
            ))}
          </Grid>
          <p>
            <strong>Name:</strong> {postDetails.username}&nbsp;&nbsp;
            <strong>Contact :</strong> {postDetails.contact} <br />
            <strong>Created At:</strong> {postDetails.created_at}
          </p>
          <p>
            <strong>Likes:</strong> {postDetails.like} &nbsp;&nbsp;&nbsp;
            <strong>Dislikes:</strong> {postDetails.dislike}&nbsp;&nbsp;&nbsp;
            <strong>Views:</strong> {postDetails.views}
          </p>
          <p>
            {expanded
              ? postDetails.description
              : truncateText(postDetails.description || "", 25)}
            {postDetails.description &&
              postDetails.description.split(" ").length > 25 && (
                <span
                  onClick={() => setExpanded(!expanded)}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  {expanded ? " Less" : " Read More"}
                </span>
              )}
          </p>
        </Box>
      </Modal>
    </>
  );
}

export default Posts;
