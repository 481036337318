import { domain } from "./domain";

const apiGeneral = {
  login: `${domain}/api/v1/admin/login`,
};

//api which required authentication
const apiAuth = {
  getPlans: `${domain}/api/v1/admin/plan/get`,
  deletePlan: `${domain}/api/v1/admin/plan/delete`,
  createPlan: `${domain}/api/v1/admin/plan/create`,
  dashboardData: `${domain}/api/v1/admin/dashboard`,

  getUsers: `${domain}/api/v1/admin/users`,
  setBlock: `${domain}/api/v1/admin/user/block`,

  getPosts: `${domain}/api/v1/admin/posts`,
  setBlockStatus: `${domain}/api/v1/admin/post/block`,
  setVerificationStatus: `${domain}/api/v1/admin/post/toggle-post-verification`,


  getStates: `${domain}/api/v1/states`,
  getDistricts: `${domain}/api/v1/districts`,
  getTalukas: `${domain}/api/v1/talukas`,
  getVillages: `${domain}/api/v1/villages`,

  getSubscriptions: `${domain}/api/v1/admin/subscriptions`,

  //feedback urls
  getFeedbacks: `${domain}/api/v1/admin/feedback/get`,
  readFeedback: `${domain}/api/v1/admin/feedbacks/mark-as-read`,

  getSettingCoins: `${domain}/api/v1/admin/settings/coins/get`,
  updateSettingCoins: `${domain}/api/v1/admin/settings/coins/update`,
  updateIntroVideo: `${domain}/api/v1/admin/settings/intro-video/update`,
};

export { apiAuth, apiGeneral };
