import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Template from "./layouts/Template";
import Posts from "./screens/Posts/Posts";
import Users from "./screens/Users/Users";
import { Plans } from "./screens/Plans/Plans";
import { AddPlan } from "./screens/Plans/AddPlan";
import ChangePassword from "./screens/ChangePassword";
import { LoadingProvider } from "./utils/LoadingContext";
import Feedback from "./screens/Feedback/Feedback";
import Subscriptions from "./screens/subscriptions/Subscriptions";
import Settings from "./screens/Settings/Settings";



function App() {
  return (
    <LoadingProvider>
      {" "}
      {/* Wrap with LoadingProvider */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/" element={<Template />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/posts" element={<Posts />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/users" element={<Users />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/plans/add" element={<AddPlan />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/settings" element={<Settings />} />

          </Route>
        </Routes>
      </BrowserRouter>
    </LoadingProvider>
  );
}

export default App;
